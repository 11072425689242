import {Divider, Image, List, Progress, Tabs, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {VisibilityContext} from 'react-horizontal-scrolling-menu';
import {useDispatch, useSelector} from 'react-redux';
//@ts-ignore
import {countries} from 'country-data';
import moment from 'moment';

import AttendanceCircle from '../../../assets/icons/AttendanceCircle.svg';
import Boy from '../../../assets/icons/Boy02.svg';
import PikadoSmallLogo from '../../../assets/icons/PikadoSmallLogo.svg';
import './reportPage.css';

import Title from 'antd/es/typography/Title';
import {useParams} from 'react-router-dom';
import {MonthlyReport, Statistic, Student} from '../../..//graphql/API';
import {Loading} from '../../../components';
import FooterBar from '../../../components/FooterBar';
import {setSelectedStudent} from '../../../redux/actions/student';
import {StatisticRepository} from '../StatisticRepository';
import AttendanceDashboardModal from './AttendanceDashboardModal';
import {DemoLine} from './Graph';
import useDrag from './useDrag';
import {getGradeByScore, getGradeColor} from '../../../constants';

const statisticRepository = new StatisticRepository();
type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;
type StudentReportPageType = {
  student: Student;
};

const getMonthsFromYearStart = () => {
  const startOfYear = moment().startOf('year');
  const currentMonth = moment();
  const months = [];

  while (startOfYear <= currentMonth) {
    months.push(startOfYear.format('YYYY-MM'));
    startOfYear.add(1, 'month');
  }

  return months;
};

export const StudentReportPage = ({student}: StudentReportPageType) => {
  let params: {studentId: string} = useParams();
  const {dragStart, dragStop, dragMove, dragging} = useDrag();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const dispatch = useDispatch();
  const {Text} = Typography;
  const months = getMonthsFromYearStart();

  const selectedStudent: Student = useSelector(
    (state: any) => state.student?.selectedStudent,
  );

  const [report, setReport] = useState<Statistic>();
  const [currentMonthlyReport, setCurrentMonthlyReport] =
    useState<MonthlyReport>();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [performanceData, setPerformanceData] = useState<any>([]);

  const [sortedDaysList, setSortedDaysList] = useState<any>();
  const [performaceDailyGraph, setPerformaceDailyGraph] = useState<any>([]);

  const [selectedMonth, setSelectedMonth] = useState({
    month: moment().format('YYYY-MM'),
    index: months.indexOf(moment().format('YYYY-MM')),
  });

  useEffect(() => {
    if (!selectedStudent) {
      statisticRepository.getStudentById(params.studentId).then((student) => {
        dispatch(setSelectedStudent(student));
        //getStudentFullReport();
      });
    }
  }, []);

  useEffect(() => {
    if (selectedStudent) {
      getStudentFullReport();
    }
  }, [selectedStudent, selectedMonth]);

  const getStudentFullReport = () => {
    console.log(
      'selectedStudent?.statistic?.id->',
      selectedStudent?.statistic?.id,
    );
    statisticRepository
      .getFullReport(selectedStudent?.statistic?.id!)
      .then((fullReport) => {
        let sortedMonthlyReports = fullReport?.monthlyReports.sort(
          (a: any, b: any) => {
            //@ts-ignore
            return new Date(a.month) - new Date(b.month);
          },
        );

        console.log('sortedMonthlyReports', sortedMonthlyReports);

        let performaceGraph: {Score: number; Month: string}[] = [];
        let count = 0;

        while (count < sortedMonthlyReports.length && count < 6) {
          const mr = sortedMonthlyReports[count];
          performaceGraph?.push({
            Score: mr?.average ? parseInt(mr.average + '') : 0,
            Month: moment(mr.month).format('MMM YY'),
          });
          count++;
        }

        console.log('fullReport.monthlyReports', fullReport.monthlyReports);
        console.log('performaceGraph', performaceGraph);

        const parsedMonthlyReports = fullReport.monthlyReports?.map(
          (rep: any) => {
            console.log('HELLO_MAONG', rep);
            let monthlyReportInput: any = {...rep};
            console.log('HELLO_MAONG0000', monthlyReportInput['d1']);

            for (let dayIndex = 1; dayIndex <= 31; dayIndex++) {
              console.log(
                'HELLO_MAONG222222',
                monthlyReportInput[`d${dayIndex}`],
              );
              console.log('HELLO_MAONG33333', rep[`d${dayIndex}`]);

              monthlyReportInput[`d${dayIndex}`] = JSON.parse(
                rep[`d${dayIndex}`],
              );
            }

            return monthlyReportInput;
          },
        );

        console.log('parsedMonthlyReports', parsedMonthlyReports);

        let currentMonthReport: MonthlyReport = parsedMonthlyReports?.find(
          (mr: MonthlyReport) =>
            moment(mr.month).format('YYYY-MM') === selectedMonth.month,
        );

        let performaceDailyGr: {Score: number; Month: string}[] = [];

        const isCurrentReportOld = moment(currentMonthReport?.month).isBefore(
          moment(),
          'M',
        );
        console.log('isCurrentReportOld', isCurrentReportOld);
        const endOfloop = isCurrentReportOld
          ? moment(currentMonthReport?.month).clone().endOf('month').date()
          : moment().date() - 1;

        for (let i = 1; i <= endOfloop; i++) {
          if (currentMonthReport) {
            console.log('DAYYYYY', currentMonthReport['d' + i]);

            performaceDailyGr?.push({
              Score: currentMonthReport['d' + i]?.average
                ? parseInt(currentMonthReport['d' + i].average)
                : 0,
              Month: moment(currentMonthReport?.month)
                .set('date', i)
                .format('ddd-DD'),
            });
          }
        }
        console.log('performaceDailyGr', performaceDailyGr);

        try {
          fullReport.phoneticsAsString = JSON.parse(
            fullReport.phoneticsAsString,
          );
        } catch (e) {
          console.log('Inside parsing catch->', e);
        }

        try {
          fullReport.syllablesAsString = JSON.parse(
            fullReport.syllablesAsString,
          );
        } catch (e) {
          console.log('Inside parsing catch->', e);
        }

        setPerformaceDailyGraph(performaceDailyGr);
        setPerformanceData(performaceGraph);
        console.log('currentMonthlyReport->', currentMonthReport);
        setCurrentMonthlyReport(currentMonthReport);

        setReport(fullReport);
        getSortedDaysList();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log('error 1->', error);
        alert(`Error in getting your report. Please contact us:${error}`);
      });
  };

  useEffect(() => {
    getSortedDaysList();
  }, [currentMonthlyReport]);

  const getSortedDaysList = () => {
    let list = {};
    const isCurrentReportOld = moment(currentMonthlyReport?.month).isBefore(
      moment(),
      'M',
    );
    console.log('isCurrentReportOld', isCurrentReportOld);
    const endOfloop = isCurrentReportOld
      ? moment(currentMonthlyReport?.month).clone().endOf('month').date()
      : moment().date();

    for (let i = 1; i <= endOfloop; i++) {
      if (currentMonthlyReport) {
        //@ts-ignore
        list['d' + i] = currentMonthlyReport['d' + i];
      }
    }

    console.log('sorted->', list);
    setSortedDaysList(list);
  };
  const renderReportHeader = () => {
    return (
      <div
        className="reportHeader-grid-columns"
        style={{
          border: '2px solid #ECECEC',
          display: 'grid',
        }}>
        <div style={{display: 'grid', gridTemplateColumns: '0.1fr 1fr'}}>
          <Image
            width={100}
            preview={false}
            src={Boy}
            style={{display: 'inline-block'}}
            wrapperStyle={{verticalAlign: 'top', padding: 10}}
          />
          <div
            style={{
              marginLeft: 20,
              alignSelf: 'center',
            }}>
            <Text
              style={{
                display: 'block',
                fontFamily: 'Arial Rounded MT Bold',
                fontSize: 21,
              }}>
              {selectedStudent?.name}
            </Text>
            <Text
              style={{display: 'block', fontFamily: 'Arial Rounded MT Bold'}}>
              {selectedStudent?.age},{' '}
              {countries[selectedStudent.user?.country!]?.name}
            </Text>
            <Text
              style={{
                display: 'block',
                fontFamily: 'Arial Rounded MT Bold',
                color: '#999999',
              }}>
              Since {moment(selectedStudent.createdAt).format('MMM. YYYY')}
            </Text>
          </div>
        </div>
        <div
          className="grade-border"
          style={{
            display: 'grid',
            gridTemplateColumns: '0.5fr 1fr',
            position: 'relative',
          }}>
          <div
            style={{
              backgroundColor: getGradeColor(
                selectedStudent?.statistic?.averageScore
                  ? selectedStudent?.statistic?.averageScore
                  : 0,
              ),
              position: 'relative',
            }}>
            <Text
              style={{
                color: '#FFFFFF',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                fontSize: 25,
                fontFamily: 'Arial Rounded MT Bold',
              }}>
              {getGradeByScore(
                selectedStudent?.statistic?.averageScore
                  ? selectedStudent?.statistic?.averageScore
                  : 0,
              )}
            </Text>
          </div>
          <div style={{alignSelf: 'center', marginLeft: 20}}>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                display: 'block',
                fontSize: 21,
                color: '#999999',
              }}>
              Total performance
            </Text>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                fontSize: 27,
                color: '#40B2F2',
              }}>
              {report?.averageScore}%
            </Text>
          </div>
        </div>

        <div style={{display: 'grid', gridTemplateColumns: '0.1fr 1fr'}}>
          <Image width={100} preview={false} src={PikadoSmallLogo} />
          <div style={{alignSelf: 'center'}}>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#999999',
                marginLeft: 20,
                alignSelf: 'center',
                display: 'block',
              }}>
              PikaDo Daily Exercise Report
              <br />
              Version 1.0 Generated on:{`\n`}
            </Text>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#EF426B',
                marginLeft: 20,
              }}>
              {moment(report?.fullReportLastGeneratedDate).format(
                'ddd. DD.MM.YYYY',
              ) === 'Invalid date'
                ? 'No reports generated yet.'
                : moment(report?.fullReportLastGeneratedDate).format(
                    'ddd. DD.MM.YYYY',
                  )}
            </Text>
          </div>
        </div>
      </div>
    );
  };

  const attendanceGraph = () => {
    const handleItemClick = (itemData: any) => {
      setSelectedItemData(itemData); // Set the selected item's data
      setModalOpen(true); // Open the modal
    };
    return Object.entries(sortedDaysList).map(function (
      [key, value]: any,
      index,
    ) {
      return (
        <div
          style={{
            marginBottom: 10,
            alignSelf: 'self-end',
          }}>
          <div
            style={{
              borderBottom: '1px solid #999999',
              paddingBottom: 10,
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'center',
            }}>
            {[...new Array(sortedDaysList[key].numOfTotalAns)].map(
              (_, index) => {
                return index < 5 ? (
                  <Image
                    wrapperStyle={{display: 'block'}}
                    width={8}
                    preview={false}
                    src={AttendanceCircle}
                  />
                ) : (
                  index === 5 && (
                    <Text
                      style={{
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#999999',
                        fontSize: 13,
                      }}>
                      +{sortedDaysList[key].numOfTotalAns - 5}
                    </Text>
                  )
                );
              },
            )}
          </div>

          <Text
            onClick={() => handleItemClick({...value, day: index + 1})}
            className="attendance-graph-index">
            {index + 1}
          </Text>
        </div>
      );
    });
  };
  console.log('currentMonthlyReport?.absenceDays', currentMonthlyReport);
  const renderMonthlyAttendance = () => {
    return (
      <div
        style={{
          marginTop: 30,
          overflowY: 'auto',
        }}>
        <div style={{marginBottom: 30}}>
          <Text
            style={{
              display: 'inline-block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,

              textDecoration: 'underline',
            }}>
            Attendance This Month {moment().format('MMMM')}
          </Text>
          <div style={{display: 'inline-block', float: 'right'}}>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#C3E56E',
                marginRight: 20,
                fontSize: 20,
              }}>
              {currentMonthlyReport?.attempts !== 0
                ? `${currentMonthlyReport?.attempts ?? 0} Recorded Exercises`
                : 'No Recorded Exercises for this month yet.'}
            </Text>
            <Text
              style={{
                fontFamily: 'Arial Rounded MT Bold',
                color: '#F2406A',
                fontSize: 20,
              }}>
              {currentMonthlyReport?.absenceDays
                ? `${currentMonthlyReport?.absenceDays ?? 0} Absence Days`
                : 'No attendance this month for this student'}{' '}
            </Text>
          </div>
        </div>
        <div
          style={{
            display: 'grid',
            gridAutoFlow: 'column',
            //@ts-ignore
            textAlign: '-webkit-center',
            minHeight: 200,
            marginTop: 70,
          }}>
          {attendanceGraph()}
          <AttendanceDashboardModal
            isOpen={isModalOpen}
            onClose={() => setModalOpen(false)}
            info={selectedItemData}
          />
        </div>
      </div>
    );
  };

  const RenderDailyPerformance = () => {
    return (
      <div
        className="renderDailyPerformance-grid-columns"
        style={{
          display: 'grid',
          marginBottom: 60,
        }}>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Daily Performance
          </Text>
          {/*@ts-ignore*/}
          <DemoLine data={performaceDailyGraph} />
        </div>
      </div>
    );
  };
  const renderPerformance = () => {
    return (
      <div
        className="renderPerformance-grid-columns"
        style={{
          display: 'grid',
          marginBottom: 60,
        }}>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Current Month Performance
          </Text>
          <Progress
            width={300}
            className="flex-algin-center"
            format={(percent) => (
              <div>
                <Text
                  style={{
                    display: 'block',
                    marginTop: 10,
                    fontFamily: 'Arial Rounded MT Bold',
                    color: '#40B2F2',
                    fontSize: 40,
                  }}>
                  {currentMonthlyReport?.average
                    ? currentMonthlyReport?.average?.toFixed(2)
                    : 0}
                  %
                </Text>

                <Text
                  style={{
                    display: 'block',
                    fontFamily: 'Arial Rounded MT Bold',
                    color: '#000000',
                    fontSize: 13,
                    fontWeight: 'bold',
                  }}>
                  Native American Accent
                </Text>
                <Text
                  style={{
                    display: 'block',
                    marginTop: 10,
                    fontFamily: 'Arial Rounded MT Bold',
                    color: '#999999',
                    fontSize: 13,
                  }}>
                  {moment().format('MMMM')}
                </Text>
              </div>
            )}
            type="circle"
            percent={
              currentMonthlyReport?.average ? currentMonthlyReport?.average : 0
            }
            strokeWidth={3}
            strokeColor={{'0%': '#6EBEF2', '100%': '#C3E56F'}}
          />
        </div>
        <Divider
          className="verticalDivider"
          orientation={'center'}
          style={{height: '100%', justifySelf: 'center'}}
          type={'vertical'}
        />
        <Divider
          className="horizontalDivider"
          orientation={'center'}
          style={{height: '100%', justifySelf: 'center'}}
          type={'horizontal'}
        />

        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Last 6 Months Performance
          </Text>
          {/*@ts-ignore*/}
          <DemoLine data={performanceData} />
        </div>
      </div>
    );
  };

  const renderMistakes = () => {
    return (
      <div className="renderMistakes-columns">
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Top 20 Syllables Mistakes
          </Text>
          <div className="scrollable-mistakes">
            {report?.syllablesAsString
              // @ts-ignore
              ?.sort((a, b) => b.mistakes - a.mistakes)
              .slice(0, 20)
              .map((item, index) => (
                <div
                  key={`${item.letters}+${index}`}
                  className="list-item-container">
                  <Text className="mistake-style">{item.letters}</Text>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Text
                      style={{
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {item.mistakes} Mistakes
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#999999',
                        fontSize: 18,
                      }}>
                      {item.attempts} Trials
                    </Text>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div>
          <Text
            style={{
              display: 'block',
              fontFamily: 'Arial Rounded MT Bold',
              color: '#999999',
              fontSize: 18,
              marginBottom: 30,
              marginTop: 30,
              textDecoration: 'underline',
            }}>
            Top 20 Phonetics Mistakes
          </Text>
          <div className="scrollable-mistakes">
            {report?.phoneticsAsString
              // @ts-ignore

              ?.sort((a, b) => b.mistakes - a.mistakes)
              .slice(0, 20)
              .map((item, index) => (
                <div
                  key={`${item.phone}+${index}`}
                  className="list-item-container">
                  <Text className="mistake-style">{item.phone}</Text>
                  <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Text
                      style={{
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#000000',
                        fontSize: 18,
                      }}>
                      {item.mistakes} Mistakes
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Arial Rounded MT Bold',
                        color: '#999999',
                        fontSize: 18,
                      }}>
                      {item.attempts} Trials
                    </Text>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  const renderNoReportAvailable = () => {
    return (
      <div style={{textAlign: 'center'}}>
        <Text
          style={{
            display: 'block',
            fontFamily: 'Arial Rounded MT Bold',
            color: '#000000',
            fontSize: 25,
            marginBottom: 30,
            marginTop: 50,
          }}>
          There is no report available for this class yet.
        </Text>
      </div>
    );
  };
  const generateMonthsTabs = () => {
    return months.map((month, index) => ({
      label: (
        <Text
          style={{
            display: 'block',
            fontFamily: 'Arial Rounded MT Bold',
            color: '#999999',
            fontSize: 16,
          }}>
          {moment(month, 'YYYY-MM').format('MMMM YYYY')}
        </Text>
      ),
      key: month,
      children: (
        <div className="reportPageContainer">
          {report ? (
            <>
              {!report?.fullReportLastGeneratedDate &&
                !isLoading &&
                renderNoReportAvailable()}
              {renderReportHeader()}
              {renderMonthlyAttendance()}

              <Divider />
              {performaceDailyGraph.length > 0 && (
                <>
                  <RenderDailyPerformance />
                  <Divider />
                </>
              )}
              {renderPerformance()}
              <Divider />
              {renderMistakes()}
              {report && <FooterBar />}
            </>
          ) : (
            !isLoading && (
              <Title>
                No report this month yet, student answers not available
              </Title>
            )
          )}
          {isLoading && (
            <Loading title={'Analysing your data. it may take a minute.'} />
          )}
        </div>
      ),
    }));
  };
  const handleTabChange = (key: string) => {
    setSelectedMonth({
      month: key,
      index: months.indexOf(key),
    });
  };
  return (
    <Tabs
      defaultActiveKey={selectedMonth.month}
      centered
      onChange={handleTabChange}
      items={generateMonthsTabs()}
    />
  );
};
