import React, {useState} from 'react';
import type {RadioChangeEvent} from 'antd';
import {Radio, Tabs, Typography} from 'antd';
import {useSelector} from 'react-redux';
import {reducersState} from '../redux/reducers';
import {ReportPage} from '../modules/Report';
import {Classschool} from '../graphql/API';
import moment from 'moment';

type TabPosition = 'left' | 'right' | 'top' | 'bottom';

const getMonthsFromYearStart = () => {
  const startOfYear = moment().startOf('year');
  const currentMonth = moment();
  const months = [];

  while (startOfYear <= currentMonth) {
    months.push(startOfYear.format('YYYY-MM'));
    startOfYear.add(1, 'month');
  }

  return months;
};

const TabsHeader = () => {
  const {Text} = Typography;

  const [mode, setMode] = useState<TabPosition>('top');
  const months = getMonthsFromYearStart();
  const [selectedMonth, setSelectedMonth] = useState({
    month: moment().format('YYYY-MM'),
    index: months.indexOf(moment().format('YYYY-MM')),
  });

  const generateMonthsTabs = (classId: string) => {
    return months.map((month, index) => ({
      label: (
        <Text
          style={{
            display: 'block',
            fontFamily: 'Arial Rounded MT Bold',
            color: '#999999',
            fontSize: 16,
          }}>
          {moment(month, 'YYYY-MM').format('MMMM YYYY')}
        </Text>
      ),
      key: month,
      children: (
        <ReportPage
          classId={classId}
          selectedMonth={month}
          monthIndex={index}
        />
      ),
    }));
  };

  const school = useSelector(
    (state: reducersState) =>
      state.user?.currentUser?.school ??
      state.user?.currentUser?.teacher?.school,
  );
  const handleModeChange = (e: RadioChangeEvent) => {
    setMode(e.target.value);
  };
  const handleTabChange = (key: string) => {
    setSelectedMonth({
      month: key,
      index: months.indexOf(key),
    });
  };

  return (
    <div style={{flex: 1}}>
      <Tabs
        defaultActiveKey="1"
        tabPosition={mode}
        centered
        style={{height: 220}}
        onTabClick={(e: any) => {
          console.log('onTabClick', e);
        }}
        items={school?.classesschool?.map(
          (classSchool: Classschool, index) => ({
            label: (
              <Text
                style={{
                  display: 'block',
                  fontFamily: 'Arial Rounded MT Bold',
                  color: '#999999',
                  fontSize: 16,
                }}>
                {`Class ${classSchool?.name}`}
              </Text>
            ),
            key: classSchool.id,
            children: (
              <Tabs
                defaultActiveKey={selectedMonth.month}
                centered
                onChange={handleTabChange}
                tabPosition={mode}
                items={generateMonthsTabs(classSchool.id)}
              />
            ),
          }),
        )}
      />
    </div>
  );
};

export default TabsHeader;
